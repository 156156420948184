// extracted by mini-css-extract-plugin
export var globalWrapper = "index-module--global-wrapper--2tlAA";
export var globalHeader = "index-module--global-header--2SIQ3";
export var postListItem = "index-module--post-list-item--24GDV";
export var bio = "index-module--bio--cLBDL";
export var bioAvatar = "index-module--bio-avatar--1d5Ou";
export var blogPost = "index-module--blog-post--330JL";
export var blogPostNav = "index-module--blog-post-nav--2do42";
export var gatsbyHighlight = "index-module--gatsby-highlight--3srfp";
export var screenReaderText = "index-module--screen-reader-text--1qpeG";
export var header = "index-module--header--3qMCB";
export var imageContainer = "index-module--image-container--1zvmg";
export var imagePlaceholder = "index-module--image-placeholder--1ojMp";
export var imagePlaceholderContent = "index-module--image-placeholder-content--1oj3M";
export var resultsContainer = "index-module--results-container--3vz70";
export var dangerText = "index-module--danger-text--3Wiji";
export var userImageContainer = "index-module--user-image-container--gO0bf";
export var overlayTextContainer = "index-module--overlay-text-container--2XzMB";
export var formContainer = "index-module--form-container--3Nql9";
export var formContent = "index-module--form-content--3lRN5";